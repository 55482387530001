import * as React from 'react'
import { PageProps } from 'gatsby'

import MainLayout from 'components/layout/MainLayout'

const NotFoundPage: React.FC<PageProps> = () => (
  <MainLayout>
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn't exist... the sadness.</p>
  </MainLayout>
)

export default NotFoundPage
